import React from "react";
import {render} from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./store/store";
import App from "./app"
import dotenv from "dotenv";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import WebRtcClass_JsSIP from "./utils/webRTC_JsSIP";

export const WebRtcContextJsSIP = React.createContext({});

dotenv.config();

const store = configureStore();

render(
  <WebRtcContextJsSIP.Provider value={new WebRtcClass_JsSIP()}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </WebRtcContextJsSIP.Provider>,
  document.getElementById("app")
);

// serviceWorker.register();
serviceWorkerRegistration.register();