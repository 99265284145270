import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    preferredDevice: "",
    preferredDeviceNumbers: {
      office: "",
      mobile: "",
      custom: "",
    },
    voicemail: {
      mailbox: "",
      active: "",
    },
    fax: {
      email: "",
      active: "",
      format: "",
      number: "",
    },
    emergency: {
      location: "",
    },
    webapp: {
      version: "1.0.1",
      lastUpdate: "10/15/2020",
      language: "english",
      notifications: {
        call: true,
        fax: true,
        voicemail: true,
        recording: true,
        conversation: true,
      },
      voip: {
        active: "",
        private_id: "",
        public_id: "",
        realm: "",
        wss: ""
      },
      activeCall: false,
      openDialer: false,
      remoteNumber: "",
      callingNumber: "",
      calling: false,
      sipCalling: false,
      services: {
        admin: "no",
        call: "no",
        conference: "no",
        contacts: "no",
        fax: "no",
        sms: "no",
        voicemail: "no"
      }
    },
    sipSessions: [],
    startConference: null,
    startTransfer: null,
    landingPage: "",
  },
  reducers: {
    setUser(state, action) {
      const { userProfile } = action.payload;
      return (state = { ...state, ...userProfile });
    },
    setActiveCall(state, action) {
      return (state = {
        ...state,
        activeCall: action.payload,
      });
    },
    setOpenDialer(state, action) {
      return (state = {
        ...state,
        openDialer: action.payload,
      });
    },
    setRemoteNumber(state, action) {
      return (state = {
        ...state,
        remoteNumber: action.payload,
      })
    },
    setCallingNumber(state, action) {
      return (state = {
        ...state,
        callingNumber: action.payload,
      })
    },
    setCalling(state, action) {
      return (state = {
        ...state,
        calling: action.payload,
      })
    },
    setSipCalling(state, action) {
      return (state = {
        ...state,
        sipCalling: action.payload,
      })
    },
    setSipSessions(state, action) {
      return (state = {
        ...state,
        sipSessions: action.payload,
      })
    },
    setVoipDeactive(state) {
      return (state = {
        ...state,
        voip: {
          active: "no"
        },
      });
    },
    setStartConference(state, action) {
      return (state = {
        ...state,
        startConference: action.payload,
      }) 
    },
    setStartTransfer(state, action) {
      return (state = {
        ...state,
        startTransfer: action.payload,
      })
    },
    setLandingPage(state, action) {
      return (state = {
        ...state,
        landingPage: action.payload,
      })
    }
  },
});

export const { 
  setUser, 
  updateSettings, 
  setActiveCall, 
  setOpenDialer, 
  setRemoteNumber, 
  setCallingNumber,
  setCalling,
  setSipCalling,
  setVoipDeactive, 
  setSipSessions, 
  setStartConference,
  setStartTransfer,
  setLandingPage,
} = UserSlice.actions;
export default UserSlice.reducer;
