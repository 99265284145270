import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import UploadPhotoButton from "./uploadPhoto";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import LockIcon from "@material-ui/icons/Lock";
import {
  Grid,
  Button,
  LinearProgress,
  InputAdornment,
} from "@material-ui/core";
import {isEqual} from "lodash";
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    "& button": {
      padding: theme.spacing(1.5),
    },
  },
  avatarProfile: {
    "& img":{
      objectFit: "contain !important"
    },
    width: '256px',
    height: '256px',
  },
  formGrid: {
    "&>div": {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(5),
      },
    },
  },
  buttonContainer: {
    paddingTop: "14px !important;",
    display: "flex",
  },
  updateButton: {
    margin: "auto",
    maxWidth: "410px",
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const UserDetails = (props) => {
    const classes = useStyles();
    const {
      session,
      user,
      setUser,
      updateUserProfile,
      getUserProfileData,
    } = props;
    const { username, firstName, lastName, email, profileImage, preferredDevice } = user;
    const [base64File, setBase64File] = useState(null);
    const [formChanged, setFormChanged] = useState(false);
    const [recentlyUpdated, setRecentlyUpdated] = useState(false);
    // eslint-disable-next-line
    const [imageHash, setImageHash] = useState(Date.now());
    const [photo, setPhoto] = useState(
      `https://${session.lastServer}4.admin.my-cloudline.net:9003/${profileImage}?${imageHash}`
    );
    

    const handlePhoto = (file) => {
      // convert and set the base64 version for update later
      toBase64(file);

      // convert and set the url version for preview
      var url = URL.createObjectURL(file);
      setPhoto(url+`#${imageHash}`);
    };

    const handleValidation = (values) => {
      const errors = {};
      
      if (!values.firstName) {
        errors.firstName = "First Name Required";
      }

      if (!values.lastName) {
        errors.lastName = "Last Name Required";
      }
      
      return errors;
    };

    const toBase64 = async file => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // use a regex to remove data url part
          let res = reader.result
              .replace("data:", "")
              .replace(/^.+,/, "");
          setBase64File(res);
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = async (values, { setSubmitting }) => {

        let data = {
          token: session.token,
          server: session.lastServer,
          userProfile: {
            ...user,
            firstName: values.firstName,
            lastName: values.lastName,
            profileImage: base64File,
            timeZone: user.timeZone,
            preferredDevice: preferredDevice,
            preferredDeviceNumbers: {
              mobile: user.preferredDeviceNumbers.mobile,
              custom: user.preferredDeviceNumbers.custom,
            },
            voicemail: {
              active: user.voicemail.active,
            },
            fax: {
              email: user.fax.email,
              active: user.fax.active,
              format: user.fax.format,
            },
          },
        };

        try {
          await updateUserProfile(data);

          setSubmitting(false);

          try {
            await getUserProfileData(
              {
                token: session.token,
                server: session.lastServer,
              },setUser
            );

            setPhoto(
              `https://${
                session.lastServer
              }4.admin.my-cloudline.net:9003/${profileImage}?${Date.now()}`
            );

            setRecentlyUpdated(true);
          } catch (error) {
            setSubmitting(false);
            console.error("error with refetch",error);
          }
        } catch (error) {
          setSubmitting(false);
          console.error("error with updating profile",error);
        }
    };

    const handleFormChange = (e)=>{
      if (!isEqual(user[e.target.name], e.target.value)) {
        setRecentlyUpdated(false);
        setFormChanged(true);
      } else {
        setRecentlyUpdated(true);
        setFormChanged(false);
      }
    }

    return (
      <Formik
        initialValues={{
          username: username,
          email: email,
          firstName: firstName,
          lastName: lastName,
        }}
        validate={handleValidation}
        onSubmit={(values, setSubmitting) =>
          handleSubmit(values, setSubmitting)
        }
      >
        {({ submitForm, isSubmitting }) => (
          <Form
            onSubmit={(e) => e.preventDefault()}
            onChange={handleFormChange}
          >
            <Grid container className={classes.root} spacing={5}>
              <Grid item xs={12} md={5} className={classes.imageContainer}>
                <Avatar varialt="square" className={classes.avatarProfile} src={photo} />
                <UploadPhotoButton
                  handlePhoto={handlePhoto}
                />
              </Grid>
              <Grid item xs={12} md={7} className={classes.formGrid}>
                <Field
                  variant="outlined"
                  component={TextField}
                  fullWidth
                  disabled
                  name="username"
                  type="text"
                  label="Username"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  variant="outlined"
                  component={TextField}
                  disabled
                  fullWidth
                  name="email"
                  type="text"
                  label="E-mail"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  variant="outlined"
                  component={TextField}
                  fullWidth
                  name="firstName"
                  type="text"
                  label="First Name"
                />
                <Field
                  variant="outlined"
                  component={TextField}
                  fullWidth
                  name="lastName"
                  type="text"
                  label="Last Name"
                />
              </Grid>
              <Grid item xs={12} className={classes.buttonContainer}>
                {isSubmitting && <LinearProgress />}

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting || !formChanged || recentlyUpdated}
                  onClick={submitForm}
                  className={classes.updateButton}
                >
                  Update
                </Button>
              </Grid>
            </Grid>            
          </Form>
        )}
      </Formik>
    );
};

UserDetails.propTypes = {
  user: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  getUserProfileData: PropTypes.func.isRequired,
};

export default UserDetails;
