import React, { useState, useEffect } from "react"
import { makeStyles, darken } from "@material-ui/core/styles"
import {
    Modal,
    Fade,
    Backdrop,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Button,
    Typography,
    TextField,
} from "@material-ui/core"
import { Grid, Paper } from "@material-ui/core"
import { setError } from "../store/features/error/errorSlice"
import { connect } from "react-redux"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import {
    updateHoliday,
    createHoliday,
} from "../utils/utils"
import { areObjectsEqual } from "../utils/common"

const mapDispatchToProps = {
    setError,
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& p": {
            fontSize: "1.125rem",
            marginTop: 0,
            fontWeight: 200,
        },
        maxWidth: "100vw",
        maxHeight: "100vh",
    },
    heading: {
        marginTop: 0,
    },
    grid: {
        flexGrow: 1,
        marginBottom: theme.spacing(3),
    },
    primary: {
        background: theme.palette.primary.light,
        color: "white",
        marginLeft: theme.spacing(4),
        "&:hover": {
            background: darken(theme.palette.primary.light, 0.25),
        },
    },
    paper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        borderRadius: "4px",
        minWidth: "400px",
        minHeight: "320px",
    },
    tabPanel: {
        flex: "auto",
    },
    close: {
        position: "absolute",
        right: theme.spacing(3),
        top: theme.spacing(3),
        background: "black",
        color: "white",
        "&:hover": {
            background: "black",
        },
        [theme.breakpoints.up("md")]: {
            right: "-.65em",
            top: "-.65em",
        },
    },
    reset: {
        background: theme.palette.text.secondary,
        color: "white",
        "&:hover": {
            background: darken(theme.palette.text.secondary, .25),
        },
    },
    buttonFooter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
        marginTop: "16px",
        marginLeft: "0px",
    },
    errorMessage: {
        color: "rgba(255,82,65)",
        flex: "auto",
        marginLeft: "64px",
        marginTop: "8px",
    },
    select: {
        position: "relative", /* Ensure the dropdown renders in place */
        display: "block",
      }
}));

const Holiday = (props) => {
    const classes = useStyles();
    const {
        open,
        setOpen,
        createMode,
        setCreateMode,
        holiday,
        setHoliday,
        refetchNotifications,
        session,
        setError,
    } = props;

    // let open = true
    // let holiday = props;
    // holiday = {
    //     "hid": 1,
    //     "name": "Thanks Giving",
    //     "month": "11",
    //     "day": "28",
    //     "active": "yes"
    // }
    const [currentHoliday, setCurrentHoliday] = useState(holiday)
    const [initHoliday, setInitHoliday] = useState(holiday)
    const [submitting, setSubmitting] = useState(false)

    if (createMode && !currentHoliday) {
        const hdInit =  {
            "name": "",
            "month": "01",
            "day": "01",
            "active": "no"
        }
        setCurrentHoliday(hdInit);
        setInitHoliday(hdInit);
    }

    const clearState = () => {
        setOpen(false)
        setCreateMode(false)
        setCurrentHoliday(null)
        setInitHoliday(null)
        setHoliday(null)
    }

    useEffect(() => {
        setCurrentHoliday(holiday)
        setInitHoliday(holiday)
    }, [holiday,open]);

    const handlePropertyChange = (field, value) => {
        setCurrentHoliday({
            ...currentHoliday,
            [field]: value,
        });
    };

    const handleUpdate = async () => {
        try {
            let data = {
                hid: currentHoliday.hid,
                holidayDetails: {
                    name: currentHoliday.name,
                    month: currentHoliday.month,
                    day: currentHoliday.day,
                    active: currentHoliday.active,
                },
                token: session.token,
                server: session.lastServer,
            }
            await updateHoliday(data);
            setSubmitting(false);

            try {
                refetchNotifications()
                clearState()
                setError({
                    visible: true,
                    type: "success",
                    message: "Holiday updated."
                });
            } catch (error) {
                setSubmitting(false);
                setError({
                    visible: true,
                    type: "error",
                    message: "Failed to refetch Holidays. " + error,
                })
                setOpen(false)
            }
        } catch (error) {
            setSubmitting(false);
            setError({
                visible: true,
                type: "error",
                message: "Failed to update Holiday. " + error,
            })
        }
    };

    const handleCreate = async () => {
        try {
            let data = {
                holidayDetails: {
                    name: currentHoliday.name,
                    month: currentHoliday.month,
                    day: currentHoliday.day,
                    active: currentHoliday.active,
                },
                token: session.token,
                server: session.lastServer,
            }
            await createHoliday(data);
            setSubmitting(false);

            try {
                refetchNotifications()
                clearState()
                setError({
                    visible: true,
                    type: "success",
                    message: "Holiday created!"
                });
            } catch (error) {
                setSubmitting(false);
                setError({
                    visible: true,
                    type: "error",
                    message: "Failed to refetch Holidays. " + error,
                })
                setOpen(false)
            }
        } catch (error) {
            setSubmitting(false);
            setError({
                visible: true,
                type: "error",
                message: "Failed to create Holiday. " + error,
            })
        }
    };

    const handleReset = () => {
        setCurrentHoliday(initHoliday)
    };

    if (!open || !currentHoliday)
        return null;

    const isModified = !areObjectsEqual(currentHoliday, initHoliday)
    const monthNames = {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        "10": "October",
        "11": "November",
        "12": "December"
    };
    const daysInMonth = {
        "01": 31,
        "02": 29,
        "03": 31,
        "04": 30,
        "05": 31,
        "06": 30,
        "07": 31,
        "08": 31,
        "09": 30,
        "10": 31,
        "11": 30,
        "12": 31
    };

    const renderDays = () => {
        const numDays = daysInMonth[currentHoliday.month] || 0; // Default to 0 days if no month is selected
        return Array.from({ length: numDays }, (_, i) => (
            <MenuItem key={i + 1} value={String(i + 1).padStart(2, "0")}>
                {String(i + 1).padStart(2, "0")}
            </MenuItem>
        ));
    };

    return (
        <Modal
            aria-labelledby="internal-contact-info"
            aria-describedby="Internal Contact Info"
            className={classes.modal}
            open={open}
            onClose={clearState}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Paper className={classes.paper}>
                    <IconButton
                        aria-label="close"
                        className={classes.close}
                        size="small"
                        onClick={clearState}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Grid item xs={12} >
                        <Typography variant="h6" fontWeight="bold" gutterBottom>
                            {"Holiday"}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={currentHoliday.name}
                                    onChange={(e) => handlePropertyChange('name', e.target.value)}
                                    inputProps={{ maxLength: 32 }}
                                    error={!currentHoliday.name.trim()} // Highlights the TextField in red when true
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <Select
                                    value={currentHoliday.month}
                                    onChange={(e) => handlePropertyChange('month', e.target.value)}
                                    fullWidth
                                    size="small"
                                    MenuProps={{
                                        getContentAnchorEl: null, // Prevents auto-repositioning
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                    }}}
                                >
                                    {Object.entries(monthNames)
                                     .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort keys numerically as strings
                                     .map(([value, name]) => (
                                        <MenuItem key={value} value={value}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={3}>
                                { currentHoliday.month && (
                                // {true && (
                                    <Select
                                        value={currentHoliday.day}
                                        onChange={(e) => handlePropertyChange('day', e.target.value)}
                                        fullWidth
                                        size="small"
                                        MenuProps={{
                                            getContentAnchorEl: null, // Prevents auto-repositioning
                                            anchorOrigin: {
                                              vertical: "bottom",
                                              horizontal: "left",
                                        }}}
                                    >
                                        {renderDays()}
                                    </Select>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentHoliday.active === "yes"}
                                        onChange={(e) => handlePropertyChange('active', currentHoliday.active === "yes" ? "no" : "yes")}
                                        color="secondary"
                                    />}
                                label="Activate"
                            />
                        </Grid>
                    </Grid>

                    <div className={classes.buttonFooter}>
                        <Button
                            variant="contained"
                            size="medium"
                            onClick={handleReset}
                            className={classes.reset}
                        >
                            Reset
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            className={classes.primary}
                            disabled={submitting || !isModified}
                            onClick={(createMode) ? handleCreate : handleUpdate}
                        >
                            {(createMode) ? "Create" : "Update"}
                        </Button>
                    </div>
                </Paper>
            </Fade>
        </Modal >
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        session: state.session,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Holiday);