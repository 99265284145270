import React,{useState,useEffect} from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
  Grid,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import EnhancedTable from "./enhancedTable"
import {
  getCallJournal,
  getFaxes,
  getRecordings,
  getVoicemails,
  getSmsConversations,
  getTimeFrame,
} from "../utils/utils"
import {
  setNotifications,
  setNotificationsLoading,
} from "../store/features/notification/notificationSlice"
import { setCalls } from "../store/features/calls/callSlice"
import { setVoicemails } from "../store/features/voicemails/voicemailSlice"
import { setSmsConversations } from "../store/features/smsConversations/smsConversationsSlice"
import { setFaxes } from "../store/features/faxes/faxSlice"
import { setRecordings } from "../store/features/recordings/recordingSlice"
import { setError } from "../store/features/error/errorSlice"
import { setPersonalPhonebook } from "../store/features/phonebook/personalPhonebookSlice"
import { setTimeFrame } from "../store/features/timeFrame/timeFrameSlice"
import { resetSession } from "../store/features/session/sessionSlice"
import TimeFrameDay from "./timeFrameDay"

const mapDispatchToProps = {
  setError,
  setCalls,
  setVoicemails,
  setSmsConversations,
  setFaxes,
  setRecordings,
  setNotifications,
  setNotificationsLoading,
  setPersonalPhonebook,
  setTimeFrame,
  resetSession,
};

const headCells = () => {
  return {
    timeframe: [
      { id: "day", numeric: false, disablePadding: false, label: "Day", },
      { id: "active", numeric: false, disablePadding: false, label: "Active" },
      { id: "allDay", numeric: false, disablePadding: false, label: "All Day" },
      { id: "start", numeric: false, disablePadding: false, label: "Start" },
      { id: "end", numeric: false, disablePadding: false, label: "End" },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ]
  }
}

const TimeFrame = (props) => {
  const {
    title,
    menu,
    menuItems,
    history,
    timeFrame,
    session,
    resetSession,
    setVoicemails,
    setSmsConversations,
    setFaxes,
    setRecordings,
    setTimeFrame,
    setNotifications,
    setNotificationsLoading,
    setCalling,
    notifications,
    setValue,
    match,
    setError,
  } = props;

  const [editTimeFrameDay, setEditTimeFrameDay] = useState(false)
  const [timeFrameDay, setTimeFrameDay] = useState(null)

  let tab = match.params.tab;
  const defaultValues = {
    server: session.lastServer,
    token: session.token,
  };

  const transformTimeFrame = (timeFrame) => {
    const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  
    return daysOrder.map(day => ({
      day: day,
      active: timeFrame[day]?.status === "active",
      allDay: timeFrame[day]?.allDayMode === "yes",
      start: timeFrame[day]?.startTime,
      end: timeFrame[day]?.endTime,
      actions: [
        { type: "editTimeFrame" },
      ],
    }));
  }

  const generateRows = ()=>{
    return transformTimeFrame(timeFrame)
  }

  const parseNewItems = (data = []) => {
    return data.filter((item) => item.viewed === "no");
  };

  const parseUnreadMessages = (data = []) => {
    return data.filter((item) => item.unreadMessages !== 0);
  }

  const refetchNotifications = () => {
    const values = defaultValues;

    let fetchCalls = getCallJournal(values, setCalls),
        fetchFaxes = getFaxes(values, setFaxes),
        fetchRecordings = getRecordings(values, setRecordings),
        fetchVoicemails = getVoicemails(values, setVoicemails),
        fetchSmsConversations = getSmsConversations(values, setSmsConversations),
        fetchTimeFrame = getTimeFrame(values, setTimeFrame);

    setNotificationsLoading(true);

    Promise.all([fetchCalls, fetchFaxes, fetchRecordings, fetchVoicemails, fetchSmsConversations, fetchTimeFrame])
      .then((res) => {
        for (var i = 0; i < res.length; i++) {
          if (res[i] instanceof Error && res[i].message === "Unauthorized") {
            resetSession();
            history.push('/login');
            return;
          }
        }

        setNotifications({
          call: parseNewItems(res[0].journalEntries),
          fax: parseNewItems(res[1].faxDocuments),
          recording: parseNewItems(res[2].callRecordings),
          voicemail: parseNewItems(res[3].voicemailRecordings),
          conversation: parseUnreadMessages(res[4].Conversations),
        });
        setNotificationsLoading(false);
      })
      .catch((err) => {
        setNotificationsLoading(false);
        console.error(err);
      });
  };

  const clearCurrentNotifications = () =>{
    // let values = defaultValues;

    try {
      // updateVoicemails(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    clearCurrentNotifications();
    // eslint-disable-next-line
  }, [tab, title]);


  return (
    <Grid container>
      <Grid item xs={12}>
        <TimeFrameDay
          open={editTimeFrameDay}
          setOpen={setEditTimeFrameDay}
          timeFrameDay={timeFrameDay}
          setTimeFrameDay={setTimeFrameDay}
          refetchNotifications={refetchNotifications}
        />
        <EnhancedTable
          setError={setError}
          type="time Frame"
          headerData={headCells()}
          rowData={generateRows()}
          dense={false}
          hasActions={true}
          selectable={false}
          menu={menu ? menuItems : false}
          title={title}
          tab={'timeframe'}
          history={history}
          hasClear={false}
          handleDialClick={null}
          setCalling={setCalling}
          setValue={setValue}
          cellAlign={"left"}
          setEditTimeFrameDay={setEditTimeFrameDay}
          setTimeFrameDay={setTimeFrameDay}
          loading={notifications.loading}
          useGroupFilter={false}
          refreshDataOnSameTab={clearCurrentNotifications}
          noPagination={true}
        />
      </Grid>
    </Grid>
  );
};

TimeFrame.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkId: PropTypes.string,
    })
  ),
  tab: PropTypes.string.isRequired,
  handleDialClick: PropTypes.func.isRequired,
  setCalling: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    phonebook: state.phonebook,
    externalPhonebook: state.externalPhonebook,
    personalPhonebook: state.personalPhonebook,
    timeFrame: state.timeFrame,
    notifications: state.notifications,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TimeFrame)
);