import React, { useState, useEffect } from "react"
import { makeStyles, darken } from "@material-ui/core/styles"
import {
    Modal,
    Fade,
    Backdrop,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Button,
    Typography,
} from "@material-ui/core"
import { Grid, Paper } from "@material-ui/core"
import { setError } from "../store/features/error/errorSlice"
import { connect } from "react-redux"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import {
    updateTimeFrameDay,
} from "../utils/utils"
import { areObjectsEqual } from "../utils/common"

const mapDispatchToProps = {
    setError,
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& p": {
            fontSize: "1.125rem",
            marginTop: 0,
            fontWeight: 200,
        },
        maxWidth: "100vw",
        maxHeight: "100vh",
    },
    heading: {
        marginTop: 0,
    },
    grid: {
        flexGrow: 1,
        marginBottom: theme.spacing(3),
    },
    primary: {
        background: theme.palette.primary.light,
        color: "white",
        marginLeft: theme.spacing(4),
        "&:hover": {
            background: darken(theme.palette.primary.light, 0.25),
        },
    },
    paper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: theme.palette.background.paper,
        border: 0,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        borderRadius: "4px",
        minWidth: "400px",
        minHeight: "320px",
    },
    tabPanel: {
        flex: "auto",
    },
    close: {
        position: "absolute",
        right: theme.spacing(3),
        top: theme.spacing(3),
        background: "black",
        color: "white",
        "&:hover": {
            background: "black",
        },
        [theme.breakpoints.up("md")]: {
            right: "-.65em",
            top: "-.65em",
        },
    },
    reset: {
        background: theme.palette.text.secondary,
        color: "white",
        "&:hover": {
            background: darken(theme.palette.text.secondary, .25),
        },
    },
    buttonFooter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
        marginTop: "16px",
        marginLeft: "0px",
    },
    errorMessage: {
        color: "rgba(255,82,65)",
        flex: "auto",
        marginLeft: "64px",
        marginTop: "8px",
    }
}));

const TimeFrameDay = (props) => {
    const classes = useStyles();
    const {
        open,
        setOpen,
        timeFrameDay,
        refetchNotifications,
        session,
        setError,
    } = props;

    // let open = true
    // let timeFrameDay = props;
    // timeFrameDay = {
    //     day: "Monday",
    //     active: true,
    //     allDay: false,
    //     startTime: "09:00:00",
    //     endTime: "14:00:00",
    // }
    const [currentTimeFrameDay, setCurrentTimeFrameDay] = useState(timeFrameDay)
    const [initTimeFrameDay, setInitTimeFrameDay] = useState(timeFrameDay)
    const [submitting, setSubmitting] = useState(false)

    const handleStartTimeChange = (value, index) => {
        const updatedTime = currentTimeFrameDay.start.split(":")
        updatedTime[index] = value
        handlePropertyChange("start", updatedTime.join(":"))
    };

    const handleEndTimeChange = (value, index) => {
        const updatedTime = currentTimeFrameDay.end.split(":")
        updatedTime[index] = value
        handlePropertyChange("end", updatedTime.join(":"))
    };

    const clearState = () => {
        setOpen(false)
        setCurrentTimeFrameDay(null)
        setInitTimeFrameDay(null)
    }

    useEffect(() => {
        setCurrentTimeFrameDay(timeFrameDay)
        setInitTimeFrameDay(timeFrameDay)
    }, [timeFrameDay]);

    const handlePropertyChange = (field, value) => {
        setCurrentTimeFrameDay({
            ...currentTimeFrameDay,
            [field]: value,
        });
    };

    const handleSubmit = async () => {
        try {
            let data = {
                day: currentTimeFrameDay.day,
                timeFrameDayDetails: {
                    status: (currentTimeFrameDay.active) ? "active" : "inactive",
                    allDayMode: (currentTimeFrameDay.allDay) ? "yes" : "no",
                    startTime: currentTimeFrameDay.start,
                    endTime: currentTimeFrameDay.end,
                },
                token: session.token,
                server: session.lastServer,
            }
            await updateTimeFrameDay(data);
            setSubmitting(false);

            try {
                refetchNotifications()
                setOpen(false)
                setError({
                    visible: true,
                    type: "success",
                    message: "Day updated."
                });
            } catch (error) {
                setSubmitting(false);
                setError({
                    visible: true,
                    type: "error",
                    message: "Failed to refetch Time Frame. " + error,
                })
                setOpen(false)
            }
        } catch (error) {
            setSubmitting(false);
            setError({
                visible: true,
                type: "error",
                message: "Failed to update Day. " + error,
            })
        }
    };

    const handleReset = () => {
        setCurrentTimeFrameDay(initTimeFrameDay)
    };

    if (!open || !currentTimeFrameDay)
        return null;

    const isModified = !areObjectsEqual(currentTimeFrameDay, initTimeFrameDay)
    const hours = [...Array(24).keys()];
    const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

    return (
        <Modal
            aria-labelledby="internal-contact-info"
            aria-describedby="Internal Contact Info"
            className={classes.modal}
            open={open}
            onClose={clearState}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Paper className={classes.paper}>
                    <IconButton
                        aria-label="close"
                        className={classes.close}
                        size="small"
                        onClick={clearState}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Grid item xs={12} >
                        <Typography variant="h6" fontWeight="bold" gutterBottom>
                            {currentTimeFrameDay.day}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={currentTimeFrameDay.active}
                                            onChange={(e) => handlePropertyChange('active', !currentTimeFrameDay.active)}
                                            color="secondary"
                                        />}
                                    label="Activate"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={currentTimeFrameDay.allDay}
                                            disabled={!currentTimeFrameDay.active}
                                            onChange={(e) => handlePropertyChange('allDay', !currentTimeFrameDay.allDay)}
                                            color="secondary"
                                        />}
                                    label="All Day"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Typography>Start</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                value={currentTimeFrameDay.start.split(":")[0]}
                                onChange={(e) => handleStartTimeChange(e.target.value, 0)}
                                fullWidth
                                disabled={currentTimeFrameDay.allDay || !currentTimeFrameDay.active}
                                size="small"
                            >
                                {hours.map((hour) => (
                                    <MenuItem key={hour} value={String(hour).padStart(2, "0")}>
                                        {String(hour).padStart(2, "0")}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                value={currentTimeFrameDay.start.split(":")[1]}
                                onChange={(e) => handleStartTimeChange(e.target.value, 1)}
                                fullWidth
                                disabled={currentTimeFrameDay.allDay || !currentTimeFrameDay.active}
                                size="small"
                            >
                                {minutes.map((minute) => (
                                    <MenuItem key={minute} value={String(minute).padStart(2, "0")}>
                                        {String(minute).padStart(2, "0")}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Typography>End</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                value={currentTimeFrameDay.end.split(":")[0]}
                                onChange={(e) => handleEndTimeChange(e.target.value, 0)}
                                fullWidth
                                disabled={currentTimeFrameDay.allDay || !currentTimeFrameDay.active}
                                size="small"
                            >
                                {hours.map((hour) => (
                                    <MenuItem key={hour} value={String(hour).padStart(2, "0")}>
                                        {String(hour).padStart(2, "0")}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                value={currentTimeFrameDay.end.split(":")[1]}
                                onChange={(e) => handleEndTimeChange(e.target.value, 1)}
                                fullWidth
                                disabled={currentTimeFrameDay.allDay || !currentTimeFrameDay.active}
                                size="small"
                            >
                                {minutes.map((minute) => (
                                    <MenuItem key={minute} value={String(minute).padStart(2, "0")}>
                                        {String(minute).padStart(2, "0")}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>

                    <div className={classes.buttonFooter}>
                        <Button
                            variant="contained"
                            size="medium"
                            onClick={handleReset}
                            className={classes.reset}
                        >
                            Reset
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            className={classes.primary}
                            disabled={submitting || !isModified}
                            onClick={handleSubmit}
                        >
                            {"Update"}
                        </Button>
                    </div>
                </Paper>
            </Fade>
        </Modal >
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        session: state.session,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeFrameDay);