import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, darken } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Paper,
  Checkbox,
  Menu,
  MenuItem,
  Chip,
  Radio,
} from "@material-ui/core";
import EnhancedTableToolbar from "./tableToolbar";
import EnhancedTableHead from "./tableHead";
import DeleteForeverConfirmation from "./deleteForever";
import ActionsSpeedDial from "./tableActions";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AudioPlayer from "./audioPlayer";
import { isArray, isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
  },
  paper: {
    width: "100%",
    height: "100vh",
    maxWidth: "100vw",
  },
  tableContainer: {
    height: "100%",
    overflow: "auto",
    maxHeight: `calc(100vh - ${48 + 146 + 41.71}px)`,
    [theme.breakpoints.up("md")]: {
      maxHeight: `calc(100vh - ${64 + 146 + 61.71}px)`,
    },
  },
  conference: {
    maxHeight: `calc(100vh - ${56 + 186 + 41.71}px)`,
    [`@media (min-width: 768px) and (max-width: 800px) and (min-height: 1024px) and (max-height: 1280px) and (orientation: portrait)`]: {
      maxHeight: `calc(100vh - ${56 + 166 + 41.71}px)`,
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: `calc(100vh - ${64 + 146 + 61.71}px)`,
    },
  },
  table: {
    width: "100%",
    overflow: "auto",
    "& .MuiTableCell-paddingCheckbox": {
      paddingLeft: "1rem",
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(0, 2),
    },
    "& .MuiTableRow-root.MuiTableRow-hover:hover": {
      background: theme.palette.background.default,
    },
  },
  tableBody: {
    height: `100%`,
  },
  actions: {
    padding: 0,
    paddingRight: "1rem",
  },
  pagination: {
    borderTop: `1px solid ${theme.palette.primary.hr}`,
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      "& .MuiTablePagination-actions": {
        marginRight: theme.spacing(8),
      },
    },
  },
  urgent: {
    "&.MuiChip-root": {
      background: theme.palette.secondary.main,
      "&:hover": {
        background: darken(theme.palette.secondary.main, 0.25),
      },
    }
  },
  failed: {
    "&.MuiChip-root": {
      background: theme.palette.secondary.main,
      "&:hover": {
        background: darken(theme.palette.secondary.main, 0.25),
      },

    }
  },
  viewed: {
    "&.MuiChip-root": {
      background: theme.palette.primary.main,
      "&:hover": {
        background: darken(theme.palette.primary.main, 0.25),
      },
    }
  },
  new: {
    "&.MuiChip-root": {
      background: theme.palette.primary.success,
      "&:hover": {
        background: darken(theme.palette.primary.success, 0.25),
      },
    }
  },
  answered: {
    "&.MuiChip-root": {
      background: theme.palette.primary.success,
      "&:hover": {
        background: darken(theme.palette.primary.success, 0.25),
      },
    }
  },
  busy: {
    "&.MuiChip-root": {
      background: theme.palette.secondary.light,
      "&:hover": {
        background: darken(theme.palette.secondary.light, 0.25),
      },
    }
  },
  deleted: {
    "&.MuiChip-root": {
      background: theme.palette.text.softer,
      "&:hover": {
        background: darken(theme.palette.text.softer, 0.25),
      },
    }
  },
  noanswer: {
    "&.MuiChip-root": {
      background: theme.palette.text.secondary,
      "&:hover": {
        background: darken(theme.palette.text.secondary, 0.25),
      },
    }
  },
  chip: {
    color: "white",
  },
  menuItem: {
    "&:hover": {
      background: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable(props) {
  const classes = useStyles();
  const {
    setError,
    type,
    selectable,
    rowData,
    headerData,
    title,
    menu,
    searchFunc,
    tab,
    history,
    clearFunction,
    hasClear,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    noPagination,
    handleDialClick,
    setCalling,
    setValue,
    setOpenContactInfo,
    setEditContactInfo,
    setContact,
    setEditTimeFrameDay,
    setTimeFrameDay,
    setEditHoliday,
    setHoliday,
    setForwardItem,
    setForward,
    statusChangeFunction,
    handleDelete,
    handleDeleteForever,
    useGroupFilter,
    createItemCallback,
    createItemTooltip,
    createGroupOptions,
    setFilterGroup,
    filterGroup,
    dense,
    setTitleChangeItem,
    setEditTitle,
    setAttachment,
    setRecipient,
    setSendEmail,
    statusOptions,
    refreshDataOnSameTab,
    handleRowClick,
    cellAlign,
  } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(headerData.length > 0 ? headerData[0].label : "");
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [deleteForever, setDeleteForever] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [play, setPlay] = useState(false);
  const [playTarget, setPlayTarget] = useState("");
  const [playTargetObject, setPlayTargetObject] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rowData.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => selected.indexOf(row) !== -1;

  // const emptyRows = 
  //   rowData.length > 0
  //     ? rowsPerPage - Math.min(rowsPerPage, rowData.length - page * rowsPerPage)
  //     : rowsPerPage - Math.min(rowsPerPage, rowData.length - page * rowsPerPage)-1;

  const handleStatusMenuClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = (type) => {
    statusChangeFunction(type, currentRow.object);
    handleStatusMenuClose();
  };

  const handleStatusButtonClick = (e, item) => {
    setAnchorEl(e.currentTarget);
    setCurrentRow(item);
  }


  // item,row[item],row,title
  const renderTableCell = (row, rowValue, obj, title) => {
    if (
      title.toLowerCase() !== "fax" &&
      title.toLowerCase() !== "fax groups" &&
      title.toLowerCase() !== "call journal" &&
      title.toLowerCase() !== "voicemail" &&
      title.toLowerCase() !== "voicemail groups" &&
      title.toLowerCase() !== "call recordings" &&
      title.toLowerCase() !== "sms messages"
    ) {
      if (title.toLowerCase() === "time frame") {
        if (row === "active" || row === "allDay") {
          return renderTimeFrameTrueFalse(row, rowValue, obj)
        }
        else {
          return renderTimeFrameTime(row, rowValue, obj);
        }
      }
      else if (title.toLowerCase() === "holiday") {
        if (row === "active" || row === "allDay") {
          return renderTimeFrameTrueFalse(row, rowValue, obj)
        }
        else {
          return renderFromCell(row, rowValue, obj, title);
        }
      }
      else {
        if (title.toLowerCase() !== "notifications") {
          return rowValue;
        }
        else {
          return getNotificationStatus(row, rowValue, obj, title);
        }
      }
    }
    else {
      if (row === "status") {
        return getStatus(row, rowValue, obj, title);
      }
      else if (row === "from" && title.toLowerCase() !== "fax groups") {
        return renderFromCell(row, rowValue, obj, title);
      }
      else {
        let text = rowValue.toLowerCase() === "old"
          ? "Viewed"
          : rowValue.charAt(0).toUpperCase() + rowValue.slice(1);
        return <span>{text}</span>;
      }
    }
  }

  const renderTimeFrameTime = (row, item, obj) => 
    (obj.active && !obj.allDay) 
      ? <Typography style={{ opacity: (obj.active && !obj.allDay ? 1.0 : 0.6 ) }}>
          {item}
        </Typography>
      : <div></div> 

  const renderTimeFrameTrueFalse = (row, item, obj) => 
    (row === "active" || (row !== "active" && obj.active))
      ? <Radio  
          checked={item}
          disabled={row !== "active" ? !obj.active : false }
          style={{ pointerEvents: 'none', color: 'grey' }}
        />
      : <div></div> 

  const renderFromCell = (type, item, row, title) =>
    <Tooltip title={row.object.number} placement="right">
      <span>{item}</span>
    </Tooltip>

  const getStatus = (type, item, row, title) => {
    let text = item.toLowerCase() === "old"
      ? "Viewed"
      : item.charAt(0).toUpperCase() + item.slice(1);

    if ((title.toLowerCase() === "fax" ||
      title.toLowerCase() === "fax groups" ||
      title.toLowerCase() === "voicemail" ||
      title.toLowerCase() === "voicemail groups" ||
      title.toLowerCase() === "call recordings" ||
      title.toLowerCase() === "sms messages")) {
      return (
        <Chip
          size="small"
          label={text}
          clickable
          aria-controls="status-menu"
          aria-haspopup="true"
          color="primary"
          onDelete={(e) => handleStatusButtonClick(e, row)}
          deleteIcon={<ArrowDropDownIcon />}
          className={clsx(classes[item.toLowerCase()], classes.chip)}
        />
      );
    } else if (title.toLowerCase() === "call journal") {
      let classText = item.toLowerCase().replace(/\s/g, "");
      return (
        <Chip
          size="small"
          label={text}
          className={clsx(classes[classText], classes.chip)}
        />
      );
    }
    return <span>{text}</span>;
  }

  const getNotificationStatus = (type, item) => {
    let text =
      item.toLowerCase() === "old"
        ? "Viewed"
        : item.charAt(0).toUpperCase() + item.slice(1);

    if (
      (tab.toLowerCase() === "fax" ||
        tab.toLowerCase() === "mail" ||
        tab.toLowerCase() === "mailgroup" ||
        tab.toLowerCase() === "recording") &&
      type.toLowerCase() === "status"
    ) {
      return (
        <Chip
          size="small"
          label={text}
          aria-controls="status-menu"
          aria-haspopup="true"
          color="primary"
          className={clsx(classes[item.toLowerCase()], classes.chip)}
        />
      );
    } else if (tab.toLowerCase() === "call" && type.toLowerCase() === "status") {
      let classText = item.toLowerCase().replace(/\s/g, "");
      return (
        <Chip
          size="small"
          label={text}
          className={clsx(classes[classText], classes.chip)}
        />
      );
    }

    return <span>{text}</span>;
  };

  const statusDropdownMenu = () => {
    let statuses = ["New", "Viewed", "Urgent", "Deleted"];

    if (isArray(statusOptions) && !isEmpty(statusOptions)) {
      statuses = statusOptions;
    }

    return (
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleStatusMenuClose}
      >
        {statuses.map((type, index) => (
          <MenuItem
            onClick={(e) => handleStatusChange(type)}
            key={index}
            className={classes.menuItem}
          >
            {type}
          </MenuItem>
        ))}
      </Menu>
    );
  }

  const makeRow = (row, isItemSelected, index, labelId) => {
    let keys = Object.keys(row);

    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={index}
        selected={isItemSelected}
        className={classes.tableRow}
        onClick={() => {
          if (handleRowClick) {
            handleRowClick(row)
          }
        }}
        style={{ height: dense ? 33 : 62.2, maxHeight: dense ? 33 : 62.2 }}
      >
        {tab !== "deleted" && selectable ? (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              inputProps={{ "aria-labelledby": labelId }}
              onClick={(event) => handleClick(event, row)}
            />
          </TableCell>
        ) : null}
        <TableCell component="th" id={labelId} scope="row">
          {row[keys[0]]}
        </TableCell>
        {keys.map((item) =>
          keys.indexOf(item) !== 0 &&
            item !== "actions" &&
            item !== "object" ? (
            <TableCell align={cellAlign ? cellAlign : "right"} key={item}>
              {renderTableCell(item, row[item], row, title)}
            </TableCell>
          ) : null
        )}
        {keys.indexOf("actions") !== -1 ? (
          <TableCell align="right" className={classes.actions}>
            <ActionsSpeedDial
              actions={row["actions"]}
              rowItem={row}
              history={history}
              handleDialClick={handleDialClick}
              setCalling={setCalling}
              setValue={setValue}
              setOpenContactInfo={setOpenContactInfo}
              setEditContactInfo={setEditContactInfo}
              setContact={setContact}
              setEditTimeFrameDay={setEditTimeFrameDay}
              setTimeFrameDay={setTimeFrameDay}
              setEditHoliday={setEditHoliday}
              setHoliday={setHoliday}
              setForwardItem={setForwardItem}
              setForward={setForward}
              setDeleteForever={setDeleteForever}
              setDeleteTarget={setDeleteTarget}
              handleDelete={handleDelete}
              handleDeleteForever={handleDeleteForever}
              setPlay={setPlay}
              setPlayTarget={setPlayTarget}
              setPlayTargetObject={setPlayTargetObject}
              setTitleChangeItem={setTitleChangeItem}
              setEditTitle={setEditTitle}
              tab={tab}
              setAttachment={setAttachment}
              setRecipient={setRecipient}
              setSendEmail={setSendEmail}
            />
          </TableCell>
        ) : null}
      </TableRow>
    );

  }

  return (
    <div className={classes.root}>
      {statusDropdownMenu()}
      <DeleteForeverConfirmation
        open={deleteForever}
        setOpen={setDeleteForever}
        handleDeleteForever={handleDeleteForever}
        item={deleteTarget}
        setError={setError}
        type={type}
      />
      <AudioPlayer
        open={play}
        setOpen={setPlay}
        src={playTarget}
        from={playTargetObject}
      />
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          menu={menu ? menu : false}
          searchFunc={searchFunc}
          tab={tab}
          history={history}
          hasClear={hasClear}
          clearFunction={clearFunction}
          setPage={setPage}
          handleDelete={handleDelete}
          setDeleteTarget={setDeleteTarget}
          items={selected}
          setSelected={setSelected}
          createItemCallback={createItemCallback}
          createItemTooltip={createItemTooltip}
          useGroupFilter={useGroupFilter}
          createGroupOptions={createGroupOptions}
          setFilterGroup={setFilterGroup}
          filterGroup={filterGroup}
          refreshDataOnSameTab={refreshDataOnSameTab}
        />
        <TableContainer
          className={
            title.toLowerCase() === "conference manager"
              ? clsx(classes.conference, classes.tableContainer)
              : classes.tableContainer
          }
        >
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label={title + " table"}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowData.length}
              dense={dense}
              data={headerData}
              selectable={tab !== "deleted" ? selectable : false}
              tab={tab}
            />
            <TableBody className={classes.tableBody}>
              {stableSort(rowData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return makeRow(row, isItemSelected, index, labelId);
                })}
              {rowData.length === 0 ? (
                <TableRow style={{ height: dense ? 33 : 62.2 }}>
                  <TableCell colSpan={10} style={{ borderBottom: 0 }}>
                    {type && type.toLowerCase() === "conference"
                      ? "No conferences found."
                      : "All caught up!"}
                  </TableCell>
                </TableRow>
              ) : null}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 62.2) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {!noPagination && <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rowData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          className={classes.pagination}
        />}
      </Paper>
    </div>
  );
};

EnhancedTable.defaultProps = {
  selectable: false,
  rowData: [],
  headerData: [],
  menu: false,
  searchFunc: null,
  title: "Table",
  tab: "",
  hasClear: false,
  hasActions: false,
  rowsPerPage: 10,
  page: 0,
  dense: false,
  noPagination: false,
};

EnhancedTable.propTypes = {
  selectable: PropTypes.bool.isRequired,
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      status: PropTypes.string,
      dateTime: PropTypes.string,
    })
  ),
  headerData: PropTypes.shape({
    id: PropTypes.string,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    label: PropTypes.string,
  }),
  menu: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  searchFunc: PropTypes.func,
  title: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  hasClear: PropTypes.bool.isRequired,
  hasActions: PropTypes.bool.isRequired,
  clearFunction: PropTypes.func,
  setPage: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  noPagination: PropTypes.bool,
  handleDialClick: PropTypes.func,
  setCalling: PropTypes.func,
  setValue: PropTypes.func,
  setOpenContactInfo: PropTypes.func,
  setEditContactInfo: PropTypes.func,
  setContact: PropTypes.func,
  setEditTimeFrameDay: PropTypes.func,
  setTimeFrameDay: PropTypes.func,
  setEditHoliday: PropTypes.func,
  setHoliday: PropTypes.func,
  setForwardItem: PropTypes.func,
  setForward: PropTypes.func,
  statusChangeFunction: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDeleteForever: PropTypes.func,
  createItemCallback: PropTypes.func,
  createItemTooltip: PropTypes.string,
  useGroupFilter: PropTypes.bool.isRequired,
  createGroupOptions: PropTypes.func,
  setFilterGroup: PropTypes.func,
  filterGroup: PropTypes.string,
  statusOptions: PropTypes.arrayOf(PropTypes.string),
  dense: PropTypes.bool,
  refreshDataOnSameTab: PropTypes.func,
  handleRowClick: PropTypes.func,
  cellAlign: PropTypes.string,
};