// import SmtpService from "./smtp";
import {isArray, isEmpty} from "lodash";

const base_url = process.env.REACT_APP_BASE_URL;
const email_hash = process.env.REACT_APP_SECURE_EMAIL_HASH;

// Log User in to App
export const attemptLogin = (values)=>{
    const {username,password,server} = values;
    const endpoint =
      window.location.protocol + "//" + server + base_url + "/users/login";

    return fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: "Basic " + btoa(username + ":" + password),
      },
    })
    .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
}

// Reserve a Conference Room
export const reserveConference = (values)=>{
    const {token,server,conferenceData} = values;
    const endpoint =
      window.location.protocol + "//" + server + base_url + "/conferences";

    return fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: conferenceData.name,
        type: conferenceData.type,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
}

// Create Personal Contact
export const createPersonalContact = (values)=>{
  const {token,server,contactDetails} = values;
  const endpoint =
    window.location.protocol + "//" + server + base_url + "/phonebook/personal";

  return fetch(endpoint, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(contactDetails),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.json();
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export const createHoliday = (values)=>{
  const {token,server,holidayDetails} = values;
  const endpoint =
    window.location.protocol + "//" + server + base_url + "/holiday";

  return fetch(endpoint, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(holidayDetails),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return response.json();
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}


/**************************
 *  GET Requests
 **************************/

 
// GET User profile data
export const getUserProfileData = async (values,setter) => {
  let req = await getRequest(values,setter,"/users/profile");
  return req;
};

// GET Config settings
export const getConfig = async (values,setter) => {
  let req = await getRequest(values, setter, "/configuration/customer/init");
  return req;
};

// GET company Config
export const getSettings = async (values,setter) => {
  let req = await getRequest(values, setter, "/configuration/settings");
  return req;
};

// GET Call Journal
export const getCallJournal = async (values,setter) => {
  let req = await getRequest(values,setter,"/journal");
  return req;
};

// GET Voicemail list
export const getVoicemails = async (values,setter) => {
  let req = await getRequest(values,setter,"/voicemails");
  return req;
};

// GET Fax list
export const getFaxes = async (values,setter) => {
  let req = await getRequest(values,setter,"/faxes");
  return req;
};

// GET Audio Recording list
export const getRecordings = async (values,setter) => {
  let req = await getRequest(values, setter, "/recordings");
  return req;
};

// GET Conference list
export const getConferences = async (values,setter) => {
  let req = await getRequest(values, setter, "/conferences");
  return req;
};

// GET User Internal Phonebook
export const getPhonebook = async (values,setter) => {
  let req = await getRequest(values, setter, "/phonebook/internal");
  return req;
};

// GET User External Phonebook
export const getExternalPhonebook = async (values,setter) => {
  let req = await getRequest(values, setter, "/phonebook/external");
  return req;
};

// GET User Personal Phonebook
export const getPersonalPhonebook = async (values,setter) => {
  let req = await getRequest(values, setter, "/phonebook/personal");
  return req;
};

// GET SMS Contact Conversations
export const getSmsContactConversations = async (values, smscontact) => {
  let req = await getRequest(values,null,`/sms/message/${smscontact.id}`);
  return req;
};

// GET SMS Conversations
export const getSmsConversations = async (values,setter) => {
  let req = await getRequest(values,setter,"/sms/messages");
  // req.Conversations = req.Conversations.map(o => ({...o, unreadMessages: 2}));
  return req;
};

// GET Admin timeframe
export const getTimeFrame = async (values,setter) => {
  let req = await getRequest(values,setter,"/timeframe");
  return req;
};

// GET Admin Holiday
export const getHoliday = async (values,setter) => {
  let req = await getRequest(values,setter,"/holidays");
  return req;
};

// Make Call (GET)
export const initiateCall = async (values,signal) => {
  let { calledDevice, preferredDevice, token, server } = values;
  console.log("call values",values)

  const endpoint =
    window.location.protocol +
    "//" +
    server +
    base_url +
    `/make/call/${calledDevice}/${preferredDevice}`;

  return fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((response) => response.message)
    .catch((error) => { 
      return error.message;
    });
};

// Download Fax (GET)
export const downloadFax = async (values) => {
  let { fid, type, group, token, server } = values

  const prefix = group === 'no' ? 'user' : 'group'

  const endpoint =
    window.location.protocol +
    "//" +
    server +
    base_url +
    `/faxes/${prefix}/download/${fid}/${type}`;

  return fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.blob();
    })
    .then((response) => {
      let url = URL.createObjectURL(response);
      window.open(url);
    })
    .catch((error) => {
      return error.message;
    });
};

// Download Voicemail (GET)
export const downloadVoicemail = async (values) => {
  let { vid, token, server } = values;

  const endpoint =
    window.location.protocol +
    "//" +
    server +
    base_url +
    `/voicemails/download/${vid}`;

  return fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.blob();
    })
    .then((response) => {
      let url = URL.createObjectURL(response);
      return url;
    })
    .catch((error) => {
      return error.message;
    });
};

// Download Recording (GET)
export const downloadRecording = async (values) => {
  let { rid, token, server } = values;

  const endpoint =
    window.location.protocol +
    "//" +
    server +
    base_url +
    `/recordings/download/${rid}`;

  return fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.blob();
    })
    .then((response) => {
      let url = URL.createObjectURL(response);
      return url;
    })
    .catch((error) => {
      return error.message;
    });
};


/**************************
 *  POST Requests 
 **************************/

export const sendSms = async ( values, from, to, countryCode, toPretty, message, media ) => {
  const path = "/sms/send";
  const { token, server } = values;
  const data = {
    from_did: from,
    to_did_pretty: toPretty,
    to_did_cc: countryCode,
    to_did_num: to,
    type: (media === "") ? "sms" : "mms",
    file: media,
    message: message,
  }
  const endpoint = window.location.protocol + "//" + server + base_url + path;
  let payload = JSON.stringify(data);

  return fetch(endpoint, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: payload,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

/**************************
 *  PUT Requests 
 **************************/

export const updateConversationsViewed = async (values) => {
  let req = putRequest(values, "/sms/messages", "conversation");
  return req;
}

// Update User data on server
export const updateUserProfile = async (values) => {
  let req = putRequest(values, "/users/profile", "userProfile");
  return req;
};

// Update User data on server
export const updatePersonalContact = async (values) => {
  let req = putRequest(values, "/phonebook/personal", "contactDetails");
  return req;
};

// Update User data on server
export const updateTimeFrameDay = async (values) => {
  let req = putRequest(values, `/timeframe/${values.day}`, "timeFrameDayDetails");
  return req;
};

// Update User data on server
export const updateHoliday = async (values) => {
  let req = putRequest(values, `/holiday/${values.hid}`, "holidayDetails");
  return req;
};

// Update User Webapp Settings
export const updateUserWebappSettings = async (values) => {
  let req = putRequest(values, "/users/webapp/settings", "settings");
  return req;
};

// Update Call Journal Viewed Status on server
export const updateCallJournal = async (values) => {
  if(isArray(values.call) && isEmpty(values.call))
    return;

  let req = putRequest(values, "/journal", "call");
  return req;
};

// Update Voicemail Viewed Status on server
export const updateVoicemailViewed = async (values) => {
  let req = putRequest(values, "/voicemails", "voicemail");
  return req;
};

// Update Audio Recordings Viewed Status on server
export const updateRecordingsViewed = async (values) => {
  let req = putRequest(values, "/recordings", "recording");
  return req;
};

// Update Faxes Viewed Flag on server
export const updateFaxes = async (values) => {
  if(isArray(values.fax) && isEmpty(values.fax))
    return;

  let req = putRequest(values, "/faxes", "fax");
  return req;
};

// Forward Fax
export const forwardFaxes = async (values,fid,group) => {
  const prefix = group === 'no' ? 'user' : 'group'
  let req = putRequest(values, `/faxes/${prefix}/forward/${fid}`, "fax");
  return req;
};

// Update Voicemails 
export const updateVoicemails = async (values) => {
  if(isArray(values.voicemail) && isEmpty(values.voicemail))
    return;

  let req = putRequest(values, "/voicemails", "voicemail");
  return req;
};

// Update Voicemails status
export const updateVoicemailStatus = async (values,vid,status) => {
  let req = putRequest(values, `/voicemails/${vid}/${status}`, "voicemail");
  return req;
};

// Forward Voicemail
export const forwardVoicemail = async (values,vid) => {
  let req = putRequest(values, `/voicemails/forward/${vid}`, "voicemail");
  return req;
};

// Update Recordings 
export const updateRecordings = async (values) => {
  if(isArray(values.recordings) && isEmpty(values.recordings))
    return;
  
  let req = putRequest(values, "/recordings", "recordings");
  return req;
};

// Update Recording status
export const updateRecordingStatus = async (values, rid, status) => {
  let req = putRequest(
    values,
    `/recordings/${rid}/${status}`,
    "recording"
  );
  return req;
};

// Forward Recording
export const forwardRecording = async (values,rid) => {
  let req = putRequest(values, `/recordings/forward/${rid}`, "recording");
  return req;
};

// Update Conference
export const updateConference = async (values,cid) => {
  let req = putRequest(values, `/conferences/${cid}`, "conference");
  return req;
};



/**************************
 *  DELETE Requests 
 **************************/

// Delete fax on server
export const deleteFax = async (values) => {
  const prefix = values.group === 'no' ? 'user' : 'group'
  let req = deleteRequest(values, `/faxes/${prefix}`);
  return req;
};

// Delete voicemail on server
export const deleteVoicemail = async (values) => {
  let req = deleteRequest(values, "/voicemails");
  return req;
};

// Delete recording on server
export const deleteRecording = async (values) => {
  let req = deleteRequest(values, "/recordings");
  return req;
};

// Delete recording on server
export const deleteConference = async (values) => {
  let req = deleteRequest(values, "/conferences");
  return req;
};

// Delete recording on server
export const deletePersonalContact = async (values) => {
  let req = await deleteRequest(values, "/phonebook/personal");
  return req;
};

export const deleteHoliday = async (values) => {
  let req = await deleteRequest(values, "/holiday");
  return req;
}

/********************************
 *  Generic Request functions
 ********************************/

// Generic GET Request Function
// @params: values should be an object containing a token and server
// @params: setter is a function to save data to store
// @params: path is a string with url path for the endpoint in format "/endpoint"

export const getRequest = (values,setter=null,path) => {
  const { token, server } = values;
  const endpoint =
    window.location.protocol + "//" + server + base_url + path;

  return fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((response) => {
      if(setter!==null){
        setter(Object.assign(response, values));
      }
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Generic POST Request Function
// @params: values should be an object containing a token,server, and data object
// @params: path is a string with url path for the endpoint in format "/endpoint"
// @params: dataKey is a string with the name of the key where the data object is stored

export const postRequest = (values,path,dataKey) => {
  return requestWithData("POST", values, dataKey, path);
};

// Generic PUT Request Function
// @params: values should be an object containing a token,server, and data object
// @params: path is a string with url path for the endpoint in format "/endpoint"
// @params: dataKey is a string with the name of the key where the data object is stored

export const putRequest = (values,path,dataKey) => {
  return requestWithData("PUT", values, dataKey, path);
};


// Generic DELETE Request Function
// @params: values should be an object containing a token,server, and data object
// @params: path is a string with url path for the endpoint in format "/endpoint"

export const deleteRequest = (values,path) => {
  const { token, server, id } = values;
  const endpoint =
    window.location.protocol + "//" + server + base_url + path +"/"+id;

  return fetch(endpoint, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((response) => {
      return response;
    })
    // .catch((error) => {
    //   return error;
    // });
};



/**************************************
 * Function to send email using smtp.js
 **************************************/
 export const forwardViaEmail = (values,data)=>{
   const { server, token, id, type, group } = values;
   let path;
   if (type.toLowerCase() === "fax") {
     const prefix = group === 'no' ? 'user' : 'group'
     path = `/faxes/${prefix}/download/${id}/pdf`;
   } else if (type.toLowerCase() === "voicemail") {
     path = `/voicemails/download/${id}`;
   }

   const endpoint =
     window.location.protocol +
     "//" +
     server +
     base_url +
     path;

   fetch(endpoint, {
     method: "GET",
     headers: {
       Authorization: `Bearer ${token}`,
     },
   })
     .then((response) => {
       if (!response.ok) {
         throw new Error(response.statusText);
       }
       return response.blob();
     })
     .then((blob) => {
       sendEmailWithAttachment(data,blob);
     })
     .catch((error) => {
       return error.message;
     });
 }

 const sendEmailWithAttachment = (data,blob) =>{
   const { to, from, subject, body, title } = data;
   let Email = window.Email;
   let reader = new FileReader();

   reader.readAsBinaryString(blob);
   reader.onload = function (file) {
     let dataUri = "data:" + blob.type + ";base64," + btoa(reader.result);

     let values = {
       SecureToken: email_hash,
       To: to,
       From: from,
       Subject: subject,
       Body: body,
       Attachments: [
         {
           name: `${title}.${blob.type.split('/')[1]}`,
           data: dataUri,
         },
       ],
     };
     return Email.send(values)
       .then((message) => message)
       .catch((error) => error);
   };

   reader.onerror = function () {
     console.log("there are some problems");
   };
 }

 export const sendEmail = (data) => {
  const { to, from, subject, body } = data;
  let Email = window.Email;

  let values = {
    SecureToken: email_hash,
    To: to,
    From: from,
    Subject: subject,
    Body: body,
    Attachments: [],
  };
  return Email.send(values)
    .then((message) => message)
    .catch((error) => error);
}

function requestWithData(httpMethod, values, dataKey, path) {
  const { token, server } = values;
  const data = values[dataKey];
  const endpoint = window.location.protocol + "//" + server + base_url + path;
  let payload = JSON.stringify(data);

  return fetch(endpoint, {
    method: httpMethod,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: payload,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export const urldecode = (str) => {
  return decodeURIComponent((str+'').replace(/\+/g, '%20'));
}

export const sanitizePhoneNumber = (input) => {
  return input.replace(/[^0-9+]/g, '').replace(/^\+1/g, '1').replace(/^\+/g,'011');
}