import { createSlice } from "@reduxjs/toolkit";

const HolidaySlice = createSlice({
  name: "holiday",
  initialState: [],
  reducers: {
    setHoliday(state, action) {
      let holiday = action.payload.holiday;
      return holiday;
    },
  },
});

export const { setHoliday } = HolidaySlice.actions;
export default HolidaySlice.reducer;
