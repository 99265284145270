import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import Dialer from "../components/dialer";
import clsx from "clsx";

export const renderDrawerMenu = (classes, menuItems, isCurrentTab, handleDrawerToggle, routePrefix) => {
  return () => {
    return (
      <List className={classes.listRoot}>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            className={isCurrentTab(item)
              ? clsx(classes.listItems, classes.selected)
              : classes.listItems}
            component={Link}
            to={`${routePrefix}${item.to}`}
            onClick={handleDrawerToggle}
          >
            <ListItemText
              disableTypography
              primary={item.text} />
            <ChevronRightIcon
              className={isCurrentTab(item)
                ? classes.showChevron
                : classes.hideChevron} />
          </ListItem>
        ))}
      </List>
    );
  };
}

export const renderDialer = (
  voip,
  sipCalling,
  calling,
  setSipCalling,
  setCalling,
  sipMode,
  options,
  value,
  controller,
  setValue,
  handleSipDialClick,
  handleDialClick,
  deviceSelection,
  setDeviceSelection,
  selectedIndex,
  setSelectedIndex,
  dialerInputCb,
) => {
  return <Dialer
    calling={voip.active === 'yes' ? sipCalling : calling}
    setCalling={voip.active === 'yes' ? setSipCalling : setCalling}
    mode={voip.active === 'yes' ? sipMode : "newCall"}
    options={options}
    value={value}
    controller={controller}
    setValue={setValue}
    handleDialClick={voip.active === 'yes' ? handleSipDialClick : handleDialClick}
    deviceSelection={deviceSelection}
    setDeviceSelection={setDeviceSelection}
    selectedIndex={selectedIndex}
    setSelectedIndex={setSelectedIndex}
    dialerInputCb={dialerInputCb}
  />;
}

export const getPhoneBookEntry = (phonebook, number) => {
  let incomingValue = phonebook.find((e) => e.voicemail.mailbox === number);
  if (!incomingValue) {
    incomingValue = {
      email: "",
      firstName: "Unknown",
      lastName: "User",
      pid: 0,
      preferredDeviceNumbers: {
        custom: "",
        mobile: "",
        office: number
      },
      voicemail: {
        mailbox: number,
      },
    };
  }
  return incomingValue
}

export const getPhoneBookEntryByName = (phonebook, name) => {
  let incomingValue = phonebook.find((e) => e.firstName + ' ' + e.lastName === name)
  return incomingValue
}

export const webRtcNotifyCallback = ( phonebook, 
                                      setValue, 
                                      setSipMode, 
                                      setSipCalling, 
                                      webRtcContext, 
                                      setActiveCall, 
                                      setSipSessions, 
                                      history, 
                                      landingPage, 
                                      setError ) => {
  return (props) => {
    const {
      eventType, isRegisterSession, data,
    } = props

    let sessions = []
    webRtcContext.getRemoteNumbers().forEach((remoteNumber) => {
      const contact = phonebook.find((e) => e.voicemail.mailbox === remoteNumber)
      const contactName = (contact) ? contact.firstName + ' ' + contact.lastName : remoteNumber
      // init = 0
      // newcall = 1
      // ringing = 2
      // connected = 3
      // incoming = 4
      // onhold = 5
      const sessionStatus = webRtcContext.getSessionStatus(remoteNumber)
      const session = {
        remoteNumber: remoteNumber,
        contact: contact,
        // contact:{ ...contact, profileImage: "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250" },
        contactName: contactName,
        sessionStatus: sessionStatus,
        muted: webRtcContext.isMuted(remoteNumber).audio,
        recording: webRtcContext.isRecording(remoteNumber),
      }
      if (sessionStatus === 2 || sessionStatus === 3) {
        sessions.push(session)
      } else {
        sessions.push(session)
      }
    });
    setSipSessions(sessions)

    console.log("notifyCB:: " + eventType)

    if (eventType === 'new_call') {
      setActiveCall(true)
      let incomingValue = getPhoneBookEntry(phonebook, data)
      setValue(incomingValue)
      setSipMode("IncomingSipCall")
      setSipCalling(true)
      history.push(`/dashboard/${landingPage}`)
    }
    else if (eventType === 'terminated') {
      if (webRtcContext.getRemoteNumbers().length === 1 ||
        (webRtcContext.isPartOfConference(data.number) && data.originator === 'local')) {
        setActiveCall(false)
        setSipCalling(false)
        setSipMode("newSipCall")
      }
      if (data.originator === 'remote') {
        webRtcContext.hangupSession(data.number, data.originator)
      }
    }
    else if (eventType === 'connected') {
      if (!isRegisterSession) {
        setSipMode("InSipCall")
      }
    }
  };
}

export const sipUseEffect = (value, sipCalling, sipMode, webRtcContext, setActiveCall, startConference, setStartConference, startTransfer, setStartTransfer) => {
  if (value) {
    const number = value.hasOwnProperty("voicemail") ? value.voicemail.mailbox : value
    const callStatus = webRtcContext.getSessionStatus(number)
    if (sipCalling && callStatus === 0) {
      async function makeSipCall() {
        webRtcContext.call(number, () => {
          console.log("-> sipCallErrorCallback");
        });
      }
      makeSipCall()
      setActiveCall(true)
    }
  }
}

export const sipDialClick = (value, webRtcContext, setSipMode, setSipCalling) => {
  return () => {
    if (value) {
      const number = value.hasOwnProperty("voicemail") ? value.voicemail.mailbox : value
      const sipStatus = webRtcContext.getSessionStatus(number);
      if (sipStatus === 0 || sipStatus === 1)
        setSipMode("newSipCall")
      else if (sipStatus === 2)
        setSipMode("InSipCallRinging")
      else if (sipStatus === 3)
        setSipMode("InSipCall")
      else if (sipStatus === 4)
        setSipMode("IncomingSipCall")
      setSipCalling(true)
    }
  };
}

export const sipDialClickNew = (value, setValue, webRtcContext, setSipMode, sipCalling, setSipCalling, setActiveCall, startConference, setStartConference, startTransfer, setStartTransfer, phonebook) => {
  return () => {
    if (value) {
      const number = value.hasOwnProperty("voicemail") ? value.voicemail.mailbox : value
      const sipStatus = webRtcContext.getSessionStatus(number);
      if (sipStatus === 0 || sipStatus === 1)
        setSipMode("newSipCall")
      else if (sipStatus === 2)
        setSipMode("InSipCallRinging")
      else if (sipStatus === 3)
        setSipMode("InSipCall")
      else if (sipStatus === 4)
        setSipMode("IncomingSipCall")
      setSipCalling(true)
    }
    
    let fValue = value
    if (fValue && !fValue.hasOwnProperty("voicemail")) {
      fValue = getPhoneBookEntry(phonebook, fValue)
      setValue(fValue)
    }

    if (fValue) {
      const number = fValue.hasOwnProperty("voicemail") ? fValue.voicemail.mailbox : fValue
      const callStatus = webRtcContext.getSessionStatus(number)
      if (sipCalling && callStatus === 0) {
        async function makeSipCall() {
          webRtcContext.call(number, () => {
            console.log("call -> sipCallErrorCallback");
          });
        }
        async function transferSipCall(startTransfer) {
          webRtcContext.transferCall(startTransfer, number);
        }

        // async function makeSipConferenceCall() {
        //   webRtcContext.conference(number, () => {
        //     console.log("conference -> sipCallErrorCallback");
        //   });
        // }

        if (startConference) {
          makeSipCall()
          setStartConference(null)
        }
        else if (startTransfer) {
          transferSipCall(startTransfer)
          setStartTransfer(null)
        }
        else {
          makeSipCall()
        }
        setActiveCall(true)
      }
    }
  };
}

export const areObjectsEqual = (obj1, obj2) => {
  // Check if the objects are of the same type
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all key-value pairs are equal
  for (const key of keys1) {
    if (!areObjectsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};