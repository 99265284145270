import React,{useState,useEffect} from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
  Grid,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import EnhancedTable from "./enhancedTable"
import {
  getCallJournal,
  getFaxes,
  getRecordings,
  getVoicemails,
  getSmsConversations,
  getHoliday,
  deleteHoliday,
} from "../utils/utils"
import {
  setNotifications,
  setNotificationsLoading,
} from "../store/features/notification/notificationSlice"
import { setCalls } from "../store/features/calls/callSlice"
import { setVoicemails } from "../store/features/voicemails/voicemailSlice"
import { setSmsConversations } from "../store/features/smsConversations/smsConversationsSlice"
import { setFaxes } from "../store/features/faxes/faxSlice"
import { setRecordings } from "../store/features/recordings/recordingSlice"
import { setError } from "../store/features/error/errorSlice"
import { setPersonalPhonebook } from "../store/features/phonebook/personalPhonebookSlice"
import { setHoliday } from "../store/features/holiday/holidaySlice"
import { resetSession } from "../store/features/session/sessionSlice"
import Holiday from "./holiday"

const mapDispatchToProps = {
  setError,
  setCalls,
  setVoicemails,
  setSmsConversations,
  setFaxes,
  setRecordings,
  setNotifications,
  setNotificationsLoading,
  setPersonalPhonebook,
  setHoliday,
  resetSession,
};

const headCells = () => {
  return {
    holidays: [
      { id: "name", numeric: false, disablePadding: false, label: "Name", },
      { id: "date", numeric: false, disablePadding: false, label: "Date" },
      { id: "active", numeric: false, disablePadding: false, label: "Active" },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
    ]
  }
}

const Holidays = (props) => {
  const {
    title,
    menu,
    menuItems,
    history,
    holiday,
    session,
    resetSession,
    setVoicemails,
    setSmsConversations,
    setFaxes,
    setRecordings,
    setHoliday,
    setNotifications,
    setNotificationsLoading,
    setCalling,
    notifications,
    setValue,
    match,
    setError,
  } = props;

  const [openHd, setOpenHd] = useState(false)
  const [createHd, setCreateHd] = useState(false)
  const [hd, setHd] = useState(null)

  const createHolidayCb = () => {
    setOpenHd(true)
    setCreateHd(true)
  }

  let tab = match.params.tab;
  const defaultValues = {
    server: session.lastServer,
    token: session.token,
  };

  const transformHoliday = (holiday) => {
    const monthNames = {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      "10": "October",
      "11": "November",
      "12": "December"
  };

    return holiday.map(holiday => ({
        name: holiday.name,
        date: `${monthNames[holiday.month]} ${parseInt(holiday.day, 10)}`, // Convert day to number for removing leading zeros
        active: holiday.active === "yes",
        actions: [
          { type: "editHoliday" },
          { type: "deleteHoliday" },
        ],
        object: holiday,
    }));
  }

  const generateRows = ()=>{
    return transformHoliday(holiday)
  }

  const parseNewItems = (data = []) => {
    return data.filter((item) => item.viewed === "no");
  };

  const parseUnreadMessages = (data = []) => {
    return data.filter((item) => item.unreadMessages !== 0);
  }

  const refetchNotifications = () => {
    const values = defaultValues;

    let fetchCalls = getCallJournal(values, setCalls),
        fetchFaxes = getFaxes(values, setFaxes),
        fetchRecordings = getRecordings(values, setRecordings),
        fetchVoicemails = getVoicemails(values, setVoicemails),
        fetchSmsConversations = getSmsConversations(values, setSmsConversations),
        fetchHoliday = getHoliday(values, setHoliday);

    setNotificationsLoading(true);

    Promise.all([fetchCalls, fetchFaxes, fetchRecordings, fetchVoicemails, fetchSmsConversations, fetchHoliday])
      .then((res) => {
        for (var i = 0; i < res.length; i++) {
          if (res[i] instanceof Error && res[i].message === "Unauthorized") {
            resetSession();
            history.push('/login');
            return;
          }
        }

        setNotifications({
          call: parseNewItems(res[0].journalEntries),
          fax: parseNewItems(res[1].faxDocuments),
          recording: parseNewItems(res[2].callRecordings),
          voicemail: parseNewItems(res[3].voicemailRecordings),
          conversation: parseUnreadMessages(res[4].Conversations),
        });
        setNotificationsLoading(false);
      })
      .catch((err) => {
        setNotificationsLoading(false);
        console.error(err);
      });
  };

  const clearCurrentNotifications = () =>{
    // let values = defaultValues;

    try {
      // updateVoicemails(values);
      refetchNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  const handleDelete = async (item) => {
    let values = defaultValues;
    values.id = item.hid

    try {
      await deleteHoliday(values);
      try {
        await getHoliday (
            {
                token: session.token,
                server: session.lastServer,
            },
            setHoliday
        );
        setError({
          visible: true,
          type: "success",
          message: "Successfully deleted Holiday",
        });
    } catch (error) {
        setError({
            visible: true,
            type: "error",
            message: "Failed to retrieve Holidays. " + error,
        })
    }
    } catch (error) {
        setError({
          visible: true,
          type: "error",
          message: "Failed to delete Holiday. " + error,
      })
      console.error(error);
    }
  }

  useEffect(() => {
    clearCurrentNotifications();
    // eslint-disable-next-line
  }, [tab, title]);


  return (
    <Grid container>
      <Grid item xs={12}>
        <Holiday
          open={openHd}
          createMode={createHd}
          setCreateMode={setCreateHd}
          setOpen={setOpenHd}
          holiday={hd}
          setHoliday={setHd}
          refetchNotifications={refetchNotifications}
        />
        <EnhancedTable
          setError={setError}
          type="holiday"
          headerData={headCells()}
          rowData={generateRows()}
          dense={false}
          hasActions={true}
          selectable={false}
          menu={menu ? menuItems : false}
          title={title}
          tab={'holidays'}
          history={history}
          hasClear={false}
          handleDialClick={null}
          setCalling={setCalling}
          setValue={setValue}
          cellAlign={"left"}
          setEditHoliday={setOpenHd}
          setHoliday={setHd}
          handleDeleteForever={handleDelete}
          loading={notifications.loading}
          useGroupFilter={false}
          createItemCallback={createHolidayCb}
          refreshDataOnSameTab={clearCurrentNotifications}
          noPagination={true}
        />
      </Grid>
    </Grid>
  );
};

Holidays.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkId: PropTypes.string,
    })
  ),
  tab: PropTypes.string.isRequired,
  handleDialClick: PropTypes.func.isRequired,
  setCalling: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    phonebook: state.phonebook,
    externalPhonebook: state.externalPhonebook,
    personalPhonebook: state.personalPhonebook,
    holiday: state.holiday,
    notifications: state.notifications,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Holidays)
);